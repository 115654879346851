import type { JSX } from 'react';
import { CtaFeature as Cta } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type CtaFeatureProps = SliceComponentProps<Content.CtaFeatureSlice>;

const CtaFeature = ({ slice }: CtaFeatureProps): JSX.Element => (
  <Cta {...slice} />
);

export default CtaFeature;
